
























import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Event } from "@/entities";

const Store = namespace("Application");

@Component({
  name: "unit-listing"
})
export default class UnitListing extends Vue {
  public unitHeight = 400;

  @Store.Getter("event")
  public evnt!: Event;

  public viewUnit(id: string): void {
    this.$router.push({
      name: "ViewUnit",
      params: {
        region: this.evnt.region,
        year: "" + this.evnt.year,
        unitId: id
      }
    });
  }

  resize(): void {
    this.unitHeight = window.innerHeight - 130;
  }

  mounted(): void {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.resize);
  }
}
