






import UnitListing from "@/components/UnitListing.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Application,
  GetEventActionParams
} from "@/state/Application/application";
import { getModule } from "vuex-module-decorators";

const Store: Application = getModule(Application);

@Component({
  components: {
    UnitListing
  }
})
export default class ViewEvent extends Vue {
  @Prop({ required: true })
  public region!: string;

  @Prop({ required: true })
  public year!: number;

  @Prop({ required: true })
  public eventId!: string;

  mounted() {
    Store.getEvent(
      new GetEventActionParams(this.region, this.year, this.eventId)
    );
  }
}
